export const fields = [
    { key: 'index', label: "No", _style:'min-width:30px'},
    { key: 'select', label: "", _style:'min-width:30px' },
    { key: 'name', label: "Group", _style:'min-width:100px' },
    { key: 'description', label: "Deskripsi", _style:'min-width:100px' },
    { key: 'template', label: "Format Test", _style:'min-width:100px' },
    { key: 'user', label: "Dibuat Oleh", _style:'min-width:100px' },
    { key: 'created_at', label: "Tanggal Dibuat", _style:'min-width:100px' },
    { key: 'updated_at', label: "Tanggal Diubah", _style:'min-width:100px' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
